@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Default visibility setup */
.searchForm {
  display: none; /* Hidden by default on small screens */
  position: relative;
  width: 600px;
  max-width: 600px;
  height: 56px;

  margin: 62px auto 0 auto;
  background-color: var(--colorWhite);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);
}

/* Display the form on medium and larger screens */
@media (--viewportLarge) {
  .searchForm {
    display: block;
    margin-top: 64px; /* Adjust margin for larger screens */
  }
}

/* Mobile-only visibility */
.mobileVisible {
  display: block !important; /* Visible by default on smaller screens */
}

/* Hide mobile content on larger screens */
@media (--viewportLarge) {
  .mobileVisible {
    display: none !important;
  }
}

.imageContainer {
  height: 150px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 24px;
  max-width: 100%;
  gap: 16px;
  background-color: #ffff;
}

.imageWrapper {
  flex: 1 1 calc(20% - 10px);
  margin: 24px 0 8px;
  position: relative;
  width: calc(20% - 10px);
}

.imageWrapper::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

@media (max-width: 768px) {
  .imageWrapper {
    margin: 0;
    width: calc(50% - 10px);
    flex: 1 1 calc(50% - 10px);
  }
}

/* Default styles, desktop images are visible */
.desktopOnly {
  display: block;
}

/* On mobile screens (max-width: 768px), hide the desktop-only images */
@media (max-width: 768px) {
  .desktopOnly {
    display: none;
  }
}
