@import '../../../../styles/customMediaQueries.css';

.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 0;
  }
}
.blockContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
  flex-direction: column;
  margin-right: 16px;
}

.testimonialBlockWrapper {
  border: 1px solid #e25e3859;
  padding: 16px;
  border-radius: 8px;
}

.arrow {
  position: absolute;
  bottom: -40px;
  left: 0;
}

.arrow::before {
  content: '↓';
  font-size: 24px;
  color: var(--colorPrimaryButton);
}

.blockContainer:last-child .arrow {
  display: none;
}

.extraImages {
  display: none;
  flex-direction: column;
  gap: 10px;
  margin: 16px 0 0 0;
}

.extraImages img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
}

.extraImages img:first-child {
  width: 150px;
  height: 150px;
}

.testimonialBlockWrapper {
  border: 1px solid #e25e3859;
  padding: 16px;
  border-radius: 8px;
  align-items: flex-start !important;
}

/* Adjust the content based on screen size */
@media (--viewportMedium) {
  .blockContainer {
    flex-direction: row !important;
  }

  .arrow {
    bottom: auto;
    left: auto;
    right: -6%; /* Position to the right of the block */
    top: 50%;
    transform: translateY(-50%);
  }

  .arrow::before {
    content: '→';
  }

  .extraImages {
    display: flex;
  }
}
