@import '../../../../styles/customMediaQueries.css';

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.heroImageLeft,
.heroImageRight {
  width: 30%;
  max-width: 400px;
  height: auto;
  object-fit: contain;
  display: none;
}

.heroImageLeft {
  position: absolute;
  left: 0;
  bottom: 0;
}

.heroImageRight {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (--viewportLarge) {
  .heroImageLeft,
  .heroImageRight {
    display: block;
  }
}
