@import '../../../../../styles/customMediaQueries.css';

.root {
  border-radius: 4px;
}

.wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.searchFieldsWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
  flex: 1;
  margin-right: 10px;
  border-right: 1px solid var(--colorGrey100);
}

.keywordInput {
  width: 100%;
}

.keywordSelectInput {
  height: 100%;
  width: 100%;
  border-radius: 0;
  border: none;
  box-shadow: none;

  &:hover {
    border: none;
  }
}

.searchInput {
  flex-grow: 1;
  height: 100%;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border: 0;
  border-top-width: 2px;

  margin-right: 10px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border: 0;
    outline: none;
    box-shadow: unset;
  }

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }
}

.locationRoot {
  position: relative;
  display: flex;
  flex: 1;
}

.locationSearchWrapper {
  margin-right: 10px;
  border-right: 1px solid black;
}

.searchPredictions {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 16px;
  margin-bottom: 102px;

  @media (--viewportXLarge) {
    margin-bottom: 0;
  }
}

.searchSubmit {
  height: 100%;
  background-color: var(--marketplaceColor);
  border-top-right-radius: 4px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  & .iconSvgGroup {
    stroke: white;
  }

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.searchInputIcon {
  box-sizing: content-box;
  padding-left: 12px;
  margin-right: 4px;
  margin-top: 4px;
  display: flex;
  width: 20px;
  align-self: stretch;
}

.noSearchIcon {
  display: none;
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}
